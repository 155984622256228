<template>
  <div class="settingcuration_container">
    <confirm-comp
      id="genreComp"
      :text1="'선택하신 장르로 큐레이션할 경우,'"
      :text2="'분위기가 일관되지 않을 수 있습니다.'"
      :text3="'선택하신 장르로 계속 하시겠습니까?'"
      v-if="isGenreComp"
      @close-modal="onCloseGenreModal"
      @confirm-modal="onConfirmGenreModal"
    />
    <alert-modal
      v-if="isOverGenreModal"
      :alertText="'선호장르는 최대 3개까지 선택가능합니다.'"
      @closeModal="onCloseOverGenreModal"
    />
    <select-vocal
      v-if="isSelectVocal"
      :isshowjazz="isShowJazz"
      :isshowclassic="isShowClasic"
      :classicvocalvalue="classicVocalValue"
      :jazzvocalvalue="jazzVocalValue"
      @save-vocal="onSaveVocal"
      @close-selectvocal="onSelectVocal"
    />
    <!--########################### PC ###########################-->
    <div class="settingcuration_container_wrap pc">
      <ul class="flex_space_between align_center settingcuration_title_wrap">
        <li>
          <h2 class="settingcuration_title">{{ spacetype === 'Car' ? '' : '시간대별' }} 큐레이션 설정</h2>
        </li>
        <li class="position_relative">
          <img
            v-show="isMyspace_icon === 'isTrue'"
            class="cursor time_img"
            @click="linkUpdateCuration"
            src="/media/img/acute.png"
            alt="시간설정"
          />
          <img class="cursor" @click="onClickCloseInfo" src="/media/img/close_btn.png" alt="닫기" />
        </li>
      </ul>
      <ul class="curation_list">
        <li v-if="spacetype === 'Car'" class="flex_space_between curation_item">
          <label class="curation_label">차량 이용목적</label>
          <ul class="align_center">
            <li>
              <ul class="dp_flex">
                <li v-for="item in pcCarPurPose" :key="item.id">
                  <span
                    class="curation_valueitem cursor"
                    :class="{ on: curationInfo.carPurPose === item.value }"
                    @click="onClickCarPurPose(item)"
                    >{{ item.koText }}</span
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="flex_space_between curation_item">
          <label class="curation_label">국내곡 비율</label>
          <ul class="align_center">
            <li class="countdown" @click="countDown">-</li>
            <li>
              <span class="curation_valueitem on domesticRate">국내 {{ curationInfo.domesticRate }}%</span>
            </li>
            <li class="countup" @click="countUp">+</li>
          </ul>
        </li>
        <li v-if="spacetype === 'Store' || spacetype === 'Building'" class="flex_space_between curation_item">
          <label class="curation_label">주요고객층</label>
          <ul class="align_center">
            <li>
              <ul class="dp_flex">
                <li v-for="item in pcGetTarget" :key="item.id">
                  <span
                    class="curation_valueitem cursor"
                    :class="{ on: curationInfo.target.includes(item.value) }"
                    @click="onClickTarget(item)"
                    >{{ item.koText }}</span
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="flex_space_between curation_item">
          <label class="curation_label">선호 장르(최대 3개)</label>
          <ul class="align_center">
            <li>
              <ul class="dp_flex flex_end curation_genre">
                <li v-for="item in pcGenre1" :key="item.id">
                  <span
                    class="curation_valueitem cursor"
                    :class="{ on: getOnGenre(item) }"
                    @click="onClickGenre(item, item.koText)"
                    >{{ getGenreName(item) }}
                  </span>
                </li>
              </ul>
              <ul class="dp_flex flex_end">
                <li v-for="item in pcGenre2" :key="item.id">
                  <span
                    class="curation_valueitem cursor"
                    @click="onClickGenre(item, item.koText)"
                    :class="{
                      on: getOnGenre(item),
                      disabled: parseInt(curationInfo.domesticRate) === 0 && item.value === 10
                    }"
                    >{{ getGenreName(item) }}

                    <img
                      v-if="item.value === 5 || item.value === 11"
                      class="curation_valueitem_arrow"
                      :src="
                        getOnGenre(item)
                          ? '/media/img/curation/arrow_forward_black.png'
                          : '/media/img/curation/arrow_forward_white.png'
                      "
                      alt="보컬유무선택"
                    />
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="flex_space_between curation_item" v-if="curationInfo.period">
          <label class="curation_label">선호 시대</label>
          <ul class="align_center">
            <li>
              <ul class="dp_flex">
                <li v-for="item in pcPeroid" :key="item.id">
                  <span
                    class="curation_valueitem cursor"
                    @click="onClickPeroid(item)"
                    :class="{ on: curationInfo.period.includes(item.value) }"
                    >{{ item.text }}</span
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li v-if="spacetype === 'Store' || spacetype === 'Building'" class="flex_space_between curation_item">
          <label class="curation_label">주요 인테리어 색상</label>
          <ul class="align_center">
            <li>
              <ul class="dp_flex curation_color_list flex_end">
                <li v-for="item in pcGetColors1" :key="item.id">
                  <span
                    class="curation_valueitem cursor color"
                    :class="{ on: curationInfo.color === item.no }"
                    @click="onClickColor(item)"
                  >
                    <img class="curation_color_img" :src="item.subImg" :alt="item.colorNm" />
                    {{ item.colorNm }}</span
                  >
                </li>
              </ul>
              <ul class="dp_flex curation_color_list flex_end">
                <li v-for="item in pcGetColors2" :key="item.id">
                  <span
                    class="curation_valueitem cursor color"
                    @click="onClickColor(item)"
                    :class="{ on: curationInfo.color === item.no }"
                  >
                    <img class="curation_color_img" :src="item.img" :alt="item.colorNm" />
                    {{ item.colorNm }}</span
                  >
                </li>
              </ul>
              <ul class="dp_flex flex_end">
                <li v-for="item in pcGetColors3" :key="item.id">
                  <span
                    class="curation_valueitem cursor color"
                    :class="{ on: curationInfo.color === item.no }"
                    @click="onClickColor(item)"
                  >
                    <img class="curation_color_img" :src="item.img" :alt="item.colorNm" />
                    {{ item.colorNm }}</span
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
        <li v-if="spacetype === 'Store' || spacetype === 'Building'" class="flex_space_between curation_item_last">
          <label class="curation_label">주요 인테리어 소재</label>
          <ul class="align_center">
            <li>
              <ul class="dp_flex flex_end curation_valuelist_material">
                <li v-for="item in pcGetMaterial1" :key="item.no">
                  <span
                    class="curation_valueitem cursor"
                    :class="{ on: curationInfo.material.includes(item.no) }"
                    @click="onClickMaterial(item)"
                  >
                    {{ item.materialNm }}</span
                  >
                </li>
              </ul>
              <ul class="dp_flex flex_end curation_valuelist_material">
                <li v-for="item in pcGetMaterial2" :key="item.no">
                  <span
                    class="curation_valueitem cursor"
                    :class="{ on: curationInfo.material.includes(item.no) }"
                    @click="onClickMaterial(item)"
                  >
                    {{ item.materialNm }}</span
                  >
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <div class="flex_end confirm_btn_wrap">
        <button class="confirm_btn" :disabled="btnDisabled" @click="saveCuration">다음</button>
      </div>
    </div>
    <!--########################### Mobile ###########################-->
    <div class="settingcuration_container_wrap mo">
      <mo-spaceheader
        @click-pre="onClickCloseInfo"
        @link-myspace="onClickCloseInfo"
        :isTime="false"
        @link-UpdateCuration="linkUpdateCuration"
      >
        <template #header_title> {{ spacetype === 'Car' ? '' : '시간대별' }} 큐레이션 설정 </template>
      </mo-spaceheader>
      <main class="settingcuration_container_body_mo">
        <ul class="dp_flex flex_column">
          <li class="flex_column curation_item" v-if="spacetype === 'Car'">
            <label class="curation_label">차량 이용목적</label>
            <ul class="align_center carcuration_wrap">
              <li>
                <ul class="flex_wrap">
                  <li v-for="(item, index) in moCarPurPose1" :key="item.id">
                    <span
                      class="curation_valueitem cursor carpurpose"
                      :class="{ first: index === 0, on: curationInfo.carPurPose === item.value }"
                      @click="onClickCarPurPose(item)"
                      >{{ item.koText }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
            <ul class="align_center">
              <li>
                <ul class="flex_wrap">
                  <li v-for="(item, index) in moCarPurPose2" :key="item.id">
                    <span
                      class="curation_valueitem cursor carpurpose"
                      :class="{ first: index === 0, on: curationInfo.carPurPose === item.value }"
                      @click="onClickCarPurPose(item)"
                      >{{ item.koText }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="flex_column curation_item">
            <label class="curation_label">국내곡 비율</label>
            <ul class="align_center">
              <li class="countdown" @click="countDown">-</li>
              <li>
                <span class="curation_valueitem on domesticRate">국내 {{ curationInfo.domesticRate }}%</span>
              </li>
              <li class="countup" @click="countUp">+</li>
            </ul>
          </li>
          <li class="flex_column curation_item" v-if="spacetype === 'Store' || spacetype === 'Building'">
            <label class="curation_label">주요고객층</label>
            <ul class="align_center">
              <li>
                <ul class="dp_flex">
                  <li v-for="(item, index) in pcGetTarget" :key="item.id">
                    <span
                      class="curation_valueitem cursor"
                      @click="onClickTarget(item)"
                      :class="{ first: index === 0, on: curationInfo.target.includes(item.value) }"
                      >{{ item.koText }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="flex_column curation_item">
            <label class="curation_label">선호 장르(최대 3개)</label>
            <ul class="align_center">
              <li>
                <ul class="dp_flex flex_start curation_genre">
                  <li v-for="(item, index) in moGenre1" :key="item.id">
                    <span
                      class="curation_valueitem cursor"
                      @click="onClickGenre(item, item.koText)"
                      :class="{ first: index === 0, on: getOnGenre(item) }"
                      >{{ getGenreName(item) }}</span
                    >
                  </li>
                </ul>
                <ul class="dp_flex flex_start curation_genre">
                  <li v-for="(item, index) in moGenre2" :key="item.id">
                    <span
                      class="curation_valueitem cursor"
                      @click="onClickGenre(item, item.koText)"
                      :class="{
                        first: index === 0,
                        on: getOnGenre(item),
                        disabled: parseInt(curationInfo.domesticRate) === 0 && item.value === 10
                      }"
                      >{{ getGenreName(item) }}</span
                    >
                  </li>
                </ul>
                <ul class="dp_flex flex_start curation_genre">
                  <li v-for="(item, index) in moGenre3" :key="item.id">
                    <span
                      class="curation_valueitem genre_last_line cursor"
                      @click="onClickGenre(item, item.koText)"
                      :class="{ first: index === 0, on: getOnGenre(item) }"
                      >{{ getGenreName(item) }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="flex_column curation_item">
            <label class="curation_label">선호 시대</label>
            <ul class="align_center">
              <li>
                <ul class="dp_flex">
                  <li v-for="(item, index) in pcPeroid" :key="item.id">
                    <span
                      class="curation_valueitem cursor"
                      @click="onClickPeroid(item)"
                      :class="{ first: index === 0, on: curationInfo.period.includes(item.value) }"
                      >{{ item.text }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="flex_column curation_item" v-if="spacetype === 'Store' || spacetype === 'Building'">
            <label class="curation_label">주요 인테리어 색상</label>
            <ul class="align_center">
              <li>
                <ul class="dp_flex curation_color_list color flex_start">
                  <li v-for="(item, index) in moGetColors1" :key="item.id">
                    <span
                      class="curation_valueitem cursor color"
                      @click="onClickColor(item)"
                      :class="{ first: index === 0, on: curationInfo.color === item.no }"
                    >
                      <img class="curation_color_img" :src="item.subImg" :alt="item.colorNm" />
                      {{ item.shortText }}</span
                    >
                  </li>
                </ul>
                <ul class="dp_flex curation_color_list flex_start">
                  <li v-for="(item, index) in moGetColors2" :key="item.id">
                    <span
                      class="curation_valueitem cursor color"
                      @click="onClickColor(item)"
                      :class="{ first: index === 0, on: curationInfo.color === item.no }"
                    >
                      <img class="curation_color_img" :src="item.img" :alt="item.colorNm" />
                      {{ item.shortText }}</span
                    >
                  </li>
                </ul>
                <ul class="dp_flex  curation_color_list flex_start">
                  <li v-for="(item, index) in moGetColors3" :key="item.id">
                    <span
                      class="curation_valueitem cursor color"
                      @click="onClickColor(item)"
                      :class="{ first: index === 0, on: curationInfo.color === item.no }"
                    >
                      <img class="curation_color_img" :src="item.img" :alt="item.colorNm" />
                      {{ item.shortText }}</span
                    >
                  </li>
                </ul>
                <ul class="dp_flex  flex_start">
                  <li v-for="(item, index) in moGetColors4" :key="item.id">
                    <span
                      class="curation_valueitem cursor color"
                      @click="onClickColor(item)"
                      :class="{ first: index === 0, on: curationInfo.color === item.no }"
                    >
                      <img class="curation_color_img" :src="item.img" :alt="item.colorNm" />
                      {{ item.shortText }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li class="flex_column" v-if="spacetype === 'Store' || spacetype === 'Building'">
            <label class="curation_label">주요 인테리어 소재</label>
            <ul class="align_center">
              <li>
                <ul class="dp_flex flex_start curation_item_material">
                  <li v-for="(item, index) in pcGetMaterial1" :key="item.no">
                    <span
                      class="curation_valueitem material cursor"
                      @click="onClickMaterial(item)"
                      :class="{ first: index === 0, on: curationInfo.material.includes(item.no) }"
                    >
                      {{ item.materialNm }}</span
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </main>
      <footer class="settingcuration_container_footer_mo">
        <button :disabled="btnDisabled" class="confirm_btn" @click="saveCuration">완료</button>
      </footer>
    </div>
  </div>
</template>
<script>
import { cloneDeep, uniq } from 'lodash-es';
import { defineComponent } from '@vue/composition-api';
import { checkInstrumental } from '@/utils/Utils';
export default defineComponent({
  name: 'SettingCurationInfo',
  emits: ['save-curation', 'open-vocalmodal', 'close-curationinfo', 'delete-curation'],
  props: {
    datatype: {
      type: Number,
      default: -1,
      required: false
    },
    spacetype: {
      type: String,
      default: 'Store',
      required: false
    },
    curationinfo: {
      type: Object,
      default: () => {
        return {
          domesticRate: 50,
          target: [], // 고객층
          genre: [], // 장르
          period: [], // 시대
          color: -1, // 색상
          material: [], // 소재
          genreTuneN: [], // 보컬없음,
          genreTuneY: [], // 보컬있음
          carPurPose: -1 // 차량이용목적
        };
      }
    },
    isbtndiabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    'mo-spaceheader': () => import(`@/components/common/MoSpaceHeader.vue`),
    'select-vocal': () => import(`@/components/space/SelectVocal.vue`),
    'confirm-comp': () => import(`@/components/modal/Space/ConfirmComp.vue`),
    'alert-modal': () => import(`@/components/modal/Space/AlertModal.vue`)
  },
  data() {
    return {
      curationInfo: {
        domesticRate: 50,
        target: [], // 고객층
        genre: [], // 장르
        period: [], // 시대
        color: -1, // 색상
        material: [], // 소재
        genreTuneY: [],
        genreTuneN: [],
        carPurPose: []
      },
      genreObject: null,
      isSelectVocal: false,
      isShowJazz: false,
      isShowClasic: false,
      jazzVocalValue: -1,
      classicVocalValue: -1,
      isGenreComp: false,
      tempvalue: null,
      isOverGenreModal: false,
      isMyspace_icon: localStorage.getItem('isMyspace_icon')
    };
  },

  created() {
    if (this.curationinfo) this.setCurationInfo(this.curationinfo);
  },

  mounted() {},

  methods: {
    onCloseOverGenreModal() {
      this.isOverGenreModal = false;
    },
    onCloseGenreModal() {
      this.tempvalue = null;
      this.isGenreComp = false;
    },
    onConfirmGenreModal() {
      this.pushGenre(this.tempvalue);
      this.isGenreComp = false;
      this.tempvalue = null;
    },
    onLinkMyspace() {},
    setCurationInfo(curationValue) {
      if (curationValue) {
        const {
          domesticRate,
          target,
          genre,
          period,
          color,
          material,
          genreTuneN,
          genreTuneY,
          carPurpose
        } = curationValue;
        this.curationInfo.domesticRate = domesticRate;
        this.curationInfo.target = target || [];
        this.curationInfo.genre = genre || [];
        this.curationInfo.period = period || [];
        if (genreTuneN) {
          if (Array.isArray(genreTuneN)) {
            this.curationInfo.genreTuneN = genreTuneN.map(item => parseInt(item));
          } else {
            this.curationInfo.genreTuneN = genreTuneN.split(',').map(item => parseInt(item));
          }
        } else {
          this.curationInfo.genreTuneN = [];
        }

        if (genreTuneY) {
          if (Array.isArray(genreTuneY)) {
            this.curationInfo.genreTuneY = genreTuneY.map(item => parseInt(item));
          } else {
            this.curationInfo.genreTuneY = genreTuneY.split(',').map(item => parseInt(item));
          }
        } else {
          this.curationInfo.genreTuneY = [];
        }
        if (this.spacetype === 'Store' || this.spacetype === 'Building') {
          this.curationInfo.color = color || -1;
          this.curationInfo.material = material || [];
        } else {
          this.curationInfo.carPurPose = carPurpose || [];
        }
      }
    },
    onClickCloseInfo() {
      const cloneCuration = cloneDeep(this.curationInfo);
      cloneCuration.genreTuneN = cloneCuration.genreTuneN.sort((a, b) => a - b).join(',');
      cloneCuration.genreTuneY = cloneCuration.genreTuneY.sort((a, b) => a - b).join(',');

      this.$emit('close-curationinfo', cloneCuration);
    },
    onSaveVocal(val) {
      const { jazzVocal, classicVocal } = val;

      // 재즈
      const findJazzTuneYIndex = this.curationInfo.genreTuneY.findIndex(genreItem => genreItem === 5);
      const findJazzTuneNIndex = this.curationInfo.genreTuneN.findIndex(genreItem => genreItem === 5);
      const findGenreIndex = this.curationInfo.genre.findIndex(genreItem => genreItem === 5);

      if (parseInt(jazzVocal) > -1) {
        if (findJazzTuneYIndex > -1) this.curationInfo.genreTuneY.splice(findJazzTuneYIndex, 1);
        if (findJazzTuneNIndex > -1) this.curationInfo.genreTuneN.splice(findJazzTuneNIndex, 1);
        if (findGenreIndex > -1) this.curationInfo.genre.splice(findGenreIndex, 1);
        if (parseInt(jazzVocal) === 0) {
          this.curationInfo.genreTuneN.push(5);
        } else if (parseInt(jazzVocal) === 1) {
          this.curationInfo.genreTuneY.push(5);
        } else {
          this.curationInfo.genre.push(5);
        }
        this.isSelectVocal = false;
        return false;
      }

      // 클래식
      if (parseInt(classicVocal) > -1) {
        const findClassicTuneYIndex = this.curationInfo.genreTuneY.findIndex(genreItem => genreItem === 11);
        const findClassicTuneNIndex = this.curationInfo.genreTuneN.findIndex(genreItem => genreItem === 11);
        const findClassicGenreIndex = this.curationInfo.genre.findIndex(genreItem => genreItem === 11);
        if (findClassicTuneYIndex > -1) this.curationInfo.genreTuneY.splice(findClassicTuneYIndex, 1);
        if (findClassicTuneNIndex > -1) this.curationInfo.genreTuneN.splice(findClassicTuneNIndex, 1);
        if (findClassicGenreIndex > -1) this.curationInfo.genre.splice(findClassicGenreIndex, 1);

        if (parseInt(classicVocal) === 0) {
          this.curationInfo.genreTuneN.push(11);
        } else if (parseInt(classicVocal) === 1) {
          this.curationInfo.genreTuneY.push(11);
        } else {
          this.curationInfo.genre.push(11);
        }

        this.isSelectVocal = false;
      }
    },
    onSelectVocal() {
      this.isSelectVocal = false;
    },
    countDown() {
      if (parseInt(this.curationInfo.domesticRate) > 0) {
        this.curationInfo.domesticRate = parseInt(this.curationInfo.domesticRate) - 10;
        if (this.curationInfo.domesticRate < 10) {
          // 트로트 포함되어잇는 경우
          const findTrot = this.curationInfo.genre.findIndex(item => item === 10);
          if (findTrot > -1) {
            this.curationInfo.genre.splice(findTrot, 1);
          }
        }
      }
    },
    onClickClose() {
      this.$emit('close-curationinfo');
    },
    countUp() {
      if (parseInt(this.curationInfo.domesticRate) < 80) {
        this.curationInfo.domesticRate = parseInt(this.curationInfo.domesticRate) + 10;
      }
    },
    onClickCarPurPose(item) {
      const { value } = item;
      if (this.curationInfo.carPurPose === -1) {
        this.curationInfo.carPurPose = value;
      } else if (this.curationInfo.carPurPose !== value) {
        this.curationInfo.carPurPose = value;
      } else {
        this.curationInfo.carPurPose = -1;
      }
    },
    onClickTarget(item) {
      const { value } = item;
      const find = this.curationInfo.target.findIndex(item => item === value);
      if (find > -1) {
        this.curationInfo.target.splice(find, 1);
      } else {
        this.curationInfo.target.push(value);
      }
    },
    pushGenre(item) {
      const { value } = item;
      const total =
        this.curationInfo.genre.length + this.curationInfo.genreTuneN.length + this.curationInfo.genreTuneY.length;

      if (this.isVocalGenre(value)) {
        const findGenreIndex = this.curationInfo.genre.findIndex(item => item === parseInt(value));
        const findGenreTuneYIndex = this.curationInfo.genreTuneY.findIndex(item => item === parseInt(value));
        const findGenreTuneNIndex = this.curationInfo.genreTuneN.findIndex(item => item === parseInt(value));
        // 활성화 되어있는 경우
        if (findGenreIndex > -1 || findGenreTuneYIndex > -1 || findGenreTuneNIndex > -1) {
          if (findGenreIndex > -1) this.curationInfo.genre.splice(findGenreIndex, 1);
          if (findGenreTuneYIndex > -1) this.curationInfo.genreTuneY.splice(findGenreTuneYIndex, 1);
          if (findGenreTuneNIndex > -1) this.curationInfo.genreTuneN.splice(findGenreTuneNIndex, 1);
        } else {
          if (total < 3) {
            // 활성화 되어있지 않는 경우 - 모달띄우기
            this.isShowJazz = false;
            this.isShowClasic = false;
            if (value === 5) this.isShowJazz = true;
            if (value === 11) this.isShowClasic = true;

            this.jazzVocalValue = -1;
            this.classicVocalValue = -1;
            this.isSelectVocal = true;
          } else {
            //
            this.isOverGenreModal = true;
          }
        }
      } else {
        const find = this.curationInfo.genre.findIndex(item => item === value);
        if (find > -1) {
          this.curationInfo.genre.splice(find, 1);
        } else {
          if (total < 3) {
            this.curationInfo.genre.push(value);
          } else {
            this.isOverGenreModal = true;
          }
        }
      }
    },
    onClickGenre(item) {
      // 장르
      // 보컬있음
      // 보컬없음
      const { value } = item;
      const genre = uniq([
        ...this.curationInfo.genre,
        ...this.curationInfo.genreTuneN,
        ...this.curationInfo.genreTuneY
      ]);
      if (genre.length < 3) {
        if (checkInstrumental(genre, value)) {
          this.tempvalue = item;
          this.isGenreComp = true;
          return true;
        }
      }

      this.pushGenre(item);
    },
    isVocalGenre(val) {
      // 재즈,클래식
      const vocalList = [5, 11];
      if (vocalList.includes(parseInt(val))) {
        return true;
      }
      return false;
    },
    getGenreName(item) {
      const { value, koText } = item;
      if (value === 5 || value === 11) {
        const findGenreTuneY = this.curationInfo.genreTuneY.find(genreItem => genreItem === value);
        const findGenreTuneN = this.curationInfo.genreTuneN.find(genreItem => genreItem === value);
        if (findGenreTuneY) {
          return `${koText} - 보컬 없음`;
        }

        if (findGenreTuneN) {
          return `${koText} - 보컬 있음`;
        }
        return koText;
      }

      return koText;
    },
    onClickPeroid(item) {
      const { value } = item;
      const find = this.curationInfo.period.findIndex(item => item === value);
      if (find > -1) {
        this.curationInfo.period.splice(find, 1);
      } else {
        this.curationInfo.period.push(value);
      }
    },
    onClickColor(item) {
      const { no } = item;

      if (this.curationInfo.color === -1) {
        this.curationInfo.color = no;
      } else if (this.curationInfo.color !== no) {
        this.curationInfo.color = no;
      } else {
        this.curationInfo.color = -1;
      }
    },
    onClickMaterial(item) {
      const { no } = item;
      const findIdx = this.curationInfo.material.findIndex(value => value === no);
      if (findIdx > -1) {
        this.curationInfo.material.splice(findIdx, 1);
      } else {
        if (this.curationInfo.material.length < 3) {
          this.curationInfo.material.push(no);
        }
      }
    },
    saveCuration() {
      const cloneCuration = cloneDeep(this.curationInfo);
      cloneCuration.genreTuneN = cloneCuration.genreTuneN.sort((a, b) => a - b).join(',');
      cloneCuration.genreTuneY = cloneCuration.genreTuneY.sort((a, b) => a - b).join(',');

      const total =
        this.curationInfo.genre.length + this.curationInfo.genreTuneN.length + this.curationInfo.genreTuneY.length;

      if (total > 3) {
        this.isOverGenreModal = true;
        return false;
      }

      this.$emit('save-curation', cloneCuration);
    },
    getOnGenre(item) {
      const { value } = item;
      if (value === 5 || value === 11) {
        const genreTuneY = this.curationInfo.genreTuneY.findIndex(genreItem => genreItem === parseInt(value));
        const genreTuneN = this.curationInfo.genreTuneN.findIndex(genreItem => genreItem === parseInt(value));
        const genre = this.curationInfo.genre.findIndex(genreItem => genreItem === parseInt(value));
        if (genreTuneY > -1 || genreTuneN > -1 || genre > -1) {
          return true;
        }

        return false;
      }

      return this.curationInfo.genre.includes(value);
    },
    linkUpdateCuration(space) {
      // a1
      const { spaceid } = this.$route.params;

      // localStorage.setItem('spaceId', spaceid);
      localStorage.removeItem('isMyspace_icon');

      if (this.spaceType === 'Car') {
        this.$router.push({ path: `/curationsetting/${spaceid}` }).catch(() => {});
      } else {
        // 시간별 큐레이션 설정 done
        this.$router.push({ path: `/curationsettingtime/${spaceid}` }).catch(() => {});
      }
    }
  },
  computed: {
    btnDisabled() {
      if (this.spacetype === 'Store' || this.spacetype === 'Building') {
        if (
          this.curationInfo.target.length > 0 &&
          this.curationInfo.period.length > 0 &&
          this.curationInfo.color !== -1 &&
          (this.curationInfo.genre.length > 0 ||
            this.curationInfo.genreTuneN.length > 0 ||
            this.curationInfo.genreTuneY.length > 0) &&
          this.curationInfo.material.length > 0 &&
          this.curationInfo.material.length < 4 &&
          !this.isbtndiabled
        ) {
          return false;
        }
      } else {
        if (
          this.curationInfo.carPurPose !== -1 &&
          (this.curationInfo.genre.length > 0 ||
            this.curationInfo.genreTuneN.length > 0 ||
            this.curationInfo.genreTuneY.length > 0) &&
          this.curationInfo.period.length > 0 &&
          !this.isbtndiabled
        ) {
          return false;
        }
        return true;
      }

      return true;
    },
    pcCarPurPose() {
      return this.$store.getters['curation/getCarPurpose'];
    },
    moCarPurPose1() {
      return this.$store.getters['curation/getCarPurpose'].slice(0, 5);
    },
    moCarPurPose2() {
      return this.$store.getters['curation/getCarPurpose'].slice(5);
    },
    pcGetTarget() {
      return this.$store.getters['curation/getTarget'];
    },
    pcGetTargetunit() {
      return this.$store.getters['curation/getTargetUnit'];
    },
    pcGenre1() {
      const cloneGenre = cloneDeep(this.$store.getters['curation/getGenreAll']);
      return [cloneGenre[0], cloneGenre[1], cloneGenre[2], cloneGenre[3], cloneGenre[7], cloneGenre[8]];
    },
    pcGenre2() {
      const cloneGenre = cloneDeep(this.$store.getters['curation/getGenreAll']);
      return [cloneGenre[5], cloneGenre[6], cloneGenre[4], cloneGenre[9], cloneGenre[10]];
    },
    moGenre1() {
      const cloneGenre = cloneDeep(this.$store.getters['curation/getGenreAll']);

      return [cloneGenre[0], cloneGenre[1], cloneGenre[2], cloneGenre[3]];
    },
    moGenre2() {
      const cloneGenre = cloneDeep(this.$store.getters['curation/getGenreAll']);
      return [cloneGenre[8], cloneGenre[5], cloneGenre[6], cloneGenre[10]];
    },
    moGenre3() {
      const cloneGenre = cloneDeep(this.$store.getters['curation/getGenreAll']);

      return [cloneGenre[7], cloneGenre[4], cloneGenre[9]];
    },

    pcPeroid() {
      return this.$store.getters['curation/getPeroid'];
    },
    pcEmotion() {
      return this.$store.getters['curation/getEmotion'];
    },
    pcGetColors1() {
      return this.$store.getters['curation/getCurationColors'].slice(0, 6);
    },
    pcGetColors2() {
      return this.$store.getters['curation/getCurationColors'].slice(6, 12);
    },
    pcGetColors3() {
      return this.$store.getters['curation/getCurationColors'].slice(12);
    },
    moGetColors1() {
      return this.$store.getters['curation/getCurationColors'].slice(0, 4);
    },
    moGetColors2() {
      return this.$store.getters['curation/getCurationColors'].slice(4, 8);
    },
    moGetColors3() {
      return this.$store.getters['curation/getCurationColors'].slice(8, 12);
    },
    moGetColors4() {
      return this.$store.getters['curation/getCurationColors'].slice(12);
    },

    pcGetMaterial1() {
      return this.$store.getters['curation/getCurationMaterial'].slice(0, 9);
    },
    pcGetMaterial2() {
      return this.$store.getters['curation/getCurationMaterial'].slice(9);
    }
  },
  watch: {
    curationinfo(val) {
      console.log('val : ', val);

      if (val) this.setCurationInfo(val);
    }
  }
});
</script>
<style scoped src="@/assets/css/curation/settingcurationinfo.css"></style>
